import Image from "next/image";
import React, {useState} from "react";

export default function ImageWithFallback(
  {
    src,
    alt="",
    className,
    fallback: {
      size="640x16:9",
      bg="eeeeee",
      color="333333",
      text=alt||"DaMaWish",
      src:fallbackSrc
    }={}
  }
) {
  const [error, setError] = useState(!src)

  fallbackSrc = fallbackSrc
    || `https://dummyimage.com/${size}/${bg}/${color}&text=${encodeURIComponent(text)}`

  return (
    <Image
      src={error ? fallbackSrc : src}
      layout={'fill'} alt={alt} className={className}
      onError={() => setError(true)}
    />
  )
}
